/* Default Swiper navigation button styles */
.swiper-button-next,
.swiper-button-prev {
  color: #fff;
  /* Change the color of the navigation arrows */
  background-color: rgba(0, 0, 0, 0.5);
  /* Optional: change background color */
  border-radius: 50%;
  /* Optional: make buttons circular */
  width: 60px;
  /* Optional: adjust width */
  height: 60px;
  /* Optional: adjust height */
}

.carousel-container {
  width: 100%;
  max-width: 1200px;
  height: 500px;
  margin: 0 auto;
}

.carousel-slide {
  position: relative;
  width: 100%;
}

.carousel-image {
  width: 100%;
  height: auto;
  display: block;
}

.carousel-content {
  position: absolute;
  top: 360px;
  /* Distance from the bottom of the image */
  left: 0px;
  /* Distance from the left of the image */
  right: 0px;
  /* Distance from the right of the image */
  background: rgba(0, 0, 0, 0.5);
  /* Semi-transparent background */
  color: #fff;
  padding: 20px;
  border-radius: 5px;
}

.carousel-title {
  font-size: 1.5rem;
  margin: 0 0 10px 0;
}

.carousel-description {
  font-size: 1rem;
  margin: 0;
}

/* Responsive Styles */
@media (max-width: 1500px) {
  .carousel-title {
    font-size: 1.3rem;
  }

  .carousel-description {
    font-size: 0.9rem;
  }
}

@media (max-width: 992px) {
  .carousel-title {
    font-size: 1.1rem;
  }

  .carousel-description {
    font-size: 0.8rem;
  }
}

@media (max-width: 768px) {
  .carousel-content {
    top: 300px;
    left: 10px;
    right: 10px;
    padding: 10px;
  }

  .carousel-title {
    font-size: 1rem;
  }

  .carousel-description {
    font-size: 0.7rem;
  }
}

@media (max-width: 576px) {
  .carousel-container{
    height: 90vh;
  }
  .carousel-content {
    top: 50px;
    left: 10px;
    right: 10px;
    padding: 10px;
  }

  .carousel-title {
    font-size: 0.9rem;
  }

  .carousel-description {
    font-size: 0.6rem;
  }
}