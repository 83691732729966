.swiper {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  /* max-width: 200px; */
  /* Adjust as needed */
  /* max-height: 400px; */
  /* Adjust as needed */
  overflow: hidden;
  /* Hide overflow content */

  text-align: center;
  font-size: 18px;
  background: #fff;

  /* Center slide text vertically */
  display: flex;
  justify-content: center;
  align-items: center;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}